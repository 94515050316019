import { removeTrapFocus, trapFocus } from '../js/global-exports.js';

class DetailsModal extends HTMLElement {
  constructor() {
    super();
    this.detailsContainer = this.querySelector('details');
    this.summaryToggle = this.querySelector('summary');

    this.detailsContainer.addEventListener(
      'keyup',
      (event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
    );
    this.summaryToggle.addEventListener(
      'click',
      this.onSummaryClick.bind(this)
    );
    this.querySelector('button.modal__close-button[type="button"]').addEventListener(
      'click',()=>{
        this.close(this)
      }
    );
    const alternativeCloseElements = this.querySelectorAll('.js-close-modal');
    for (let i = 0; i < alternativeCloseElements.length; i++) {
      const element = alternativeCloseElements[i];
      element.addEventListener(
        'click',()=>{
          this.close(this)
        }
      );
    }

    this.summaryToggle.setAttribute('role', 'button');
    this.summaryToggle.setAttribute('aria-expanded', 'false');
  }

  isOpen() {
    return this.detailsContainer.hasAttribute('open');
  }

  onSummaryClick(event) {
    event.preventDefault();
    event.target.closest('details').hasAttribute('open')
      ? this.close()
      : this.open(event);
  }

  onBodyClick(event) {
    if (!this.contains(event.target) || event.target.classList.contains('modal-overlay')) {
      this.close(false);
    }
  }

  open(event) {
    this.onBodyClickEvent =
      this.onBodyClickEvent || this.onBodyClick.bind(this);
      if(event){
        event.target.closest('details').setAttribute('open', true);
      }else if(this.classList.contains('js-header__barcode')){
        this.querySelector(':scope > details').setAttribute('open', true);
      }
    document.body.addEventListener('click', this.onBodyClickEvent);
    document.body.classList.add('overflow-hidden');

    trapFocus(
      this.detailsContainer.querySelector('[tabindex="-1"]'),
      this.detailsContainer.querySelector('input:not([type="hidden"])')
    );
  }

  close(focusToggle = true) {
    removeTrapFocus(focusToggle ? this.summaryToggle : null);
    const modalIsOpen = this.detailsContainer.getAttribute('open');
    if(modalIsOpen) {
      this.detailsContainer.removeAttribute('open');
      document.body.removeEventListener('click', this.onBodyClickEvent);
      document.body.classList.remove('overflow-hidden');
    }
  }
}

customElements.define('details-modal', DetailsModal);

class PasswordModal extends DetailsModal {
  constructor() {
    super();

    if (this.querySelector('input[aria-invalid="true"]')) this.open({target: this.querySelector('details')});
  }
}

customElements.define('password-modal', PasswordModal);
